<template>
	<div>
		<!-- 头部logo -->
		<moveHeadLogo @navigation="navigation"></moveHeadLogo>
		
		<!-- 头部导航 -->
		<moveNavigation :headTitle="headTitle" :headImg="headImg"></moveNavigation>
		
		<div class="presidentmessage">
			<el-row class="president-imgdata">
				<el-row>
					<el-col :span="16" :offset="4">
						<img :src="leaderNotLoadImage" :onload="onLoadImg(img)" class="president-img"></img>
					</el-col>
				</el-row>
			</el-row>
				
			<el-row class="president-message">
				<el-col :span="24" v-html="list.bodyContent">
					{{list.bodyContent}}
				</el-col>
			</el-row>
		</div>
		
		<!-- 菜单导航 -->
		<moveMeauDialog :meauValue="navigationvalue" @meauFn="menu"></moveMeauDialog>
		
		<!-- 底部导航 -->
		<moveBottomNavigation></moveBottomNavigation>
	</div>
</template>

<script>
	// 图片查询接口
	import { selectPictureListById , selectContentListById } from '@/api/index.js'
	
	// 头部组件
	import moveHeadLogo from '@/components/move/moveHeadLogo.vue'
	// 头部导航
	import moveNavigation from '@/components/move/moveNavigation.vue'
	// 底部导航
	import moveBottomNavigation from '@/components/move/moveBottomNavigation.vue'
	// 菜单导航
	import moveMeauDialog from '@/components/move/moveMeauDialog.vue'
	export default
	{
		data()
		{
			return{
				// 头部导航标题
				headTitle:"CHAIR'S MESSAGE",
				// 头部导航图片
				headImg:'/index/message.png',
				// 导航值
				navigationvalue:false,
			
				list:{},
				img:'/Mimage/375_500.jpg',
				leaderNotLoadImage: "/Mimage/375_500.jpg", //领导图片未加载出来
			}
		},
		components:
		{
			moveHeadLogo,
			moveNavigation,
			moveBottomNavigation,
			moveMeauDialog,
		},
		created()
		{
			this.imgList()
		},
		methods:
		{
			// 头部传来的数据
			navigation(v)
			{
				this.navigationvalue=v;
				console.log(this.navigationvalue,'头部传递菜单导航')
			},
			// 遮罩层传回来的数据
			menu(v)
			{
				this.navigationvalue=v;
			},
			// 总经理图片
			imgList()
			{
				selectContentListById(
				{
				  chnanelType: 2,
				  isIndex: 0,
				  moduleType: 'MODULE_MESSAGE'
				})
				.then((response)=>
				{
					this.list=response.data.data[0];
					this.img=response.data.data[0].pictureUrl;
				})
			},
			// 图片加载完成时触发
			onLoadImg(imgSrc) 
			{
				return 'this.src='+'"'+imgSrc+'";this.οnlοad=null';
			},
		}
	}
</script>

<style scoped>
	.presidentmessage{
		width: 90%;
		margin-left: 5%;
	}
	.president-imgdata{
		width: 100%;
		position: relative;
	}
	.president-img{
		width: 375px;
		height: 500px;
	}
	.president-message{
		margin-top: 30px;
		text-align: left;
		line-height: 30px;
		display: inline-block;
		text-align: justify;
		text-justify: distribute-all-lines;
	}
	.president-englishName{
		margin-top: 50px;
		font-size: 30px;
		text-align: left;
	}
	.president-position{
		margin-top: 20px;
		text-align: left;
	}
	.president-data{
		height: 50px;
		background-color: rgba(12,12,12,0.5);
		position: absolute;
		bottom: 3px;
		line-height: 50px;
		color: white;
		font-size: 20px;
		text-align: center;
		padding-left: 3px;
		padding-right: 3px;
	}
</style>